<template>
  <div class="row">
    <div class="col-12 col-md-5">
      <transition name="slide-fade" appear>
        <dias-carga :datos="generales" @submit="getDashboard()"/>
      </transition>
    </div>
    <transition name="slide-fade" appear>
      <div class="col-12">
        <div class="row">
          <div class="col-lg-12">
            <b-button class="btn-detail m-2 " v-if="$ver(['Todas las Facturas']) && !es_proveedor"
              @click="verFacturasTodas()"
              v-b-popover.hover="'Ver Cfdis de todos los proveedores'">
              <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'file-invoice', }" class="icon btn-icon"/> Ver todos los CFDIs
            </b-button>
          </div>
        </div>
        <div class="table-responsive site-table2-responsive">
          <div class="row">
            <div class="col-lg-6">
              <h4>Proveedores</h4>
            </div>
            <div class="col-lg-6 text-right">
              <b-button class="btn-detail m-2"
                @click="!exportLoading ? exportExcel() : undefined"
                :disabled="exportLoading"
                title="Exportar listado a Excel">
                <font-awesome-icon v-if="exportLoading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin />
                <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'file-excel', }" class="icon btn-icon"/>
                Exportar Excel
              </b-button>
              <b-button class="btn-detail m-2"
                @click="!loading ? updateTable() : undefined"
                :disabled="loading"
                title="Actualizar listado">
                <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin />
                <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'sync-alt', }" class="icon btn-icon"/>
              </b-button>
            </div>
          </div>
          <!-- Tabla de datos -->
          <ag-grid-vue
              class="ag-grid-table ag-theme-balham"
              style='height:60vh'
              :localeText="locale"
              :animateRows="true"
              rowSelection="multiple"

              :gridOptions="gridOptions"
              :context="context"
              :suppressDragLeaveHidesColumns="true"
              :defaultColDef="defaultColDef"
              :columnDefs="columnDefs"
              :overlayNoRowsTemplate="$overlayNoRowsTemplate()"
              :statusBar="statusBar"
              :frameworkComponents="frameworkComponents"

              :rowModelType="rowModelType"
              :cacheBlockSize="paginationPageSize"

              @grid-ready="onGridReady"
              @gridSizeChanged="gridSizeChanged"
              @row-selected="rowSelected"
              @rowDoubleClicked="rowDblClick"
              @column-resized="saveColumnState"
              @column-moved="saveColumnState"
              @sort-changed="saveColumnState"
              @first-data-rendered="gridFirstDataRendered">
          </ag-grid-vue>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import DiasCarga from '@/components/DiasCarga.vue';
import XLSXSTYLE from 'sheetjs-style-v2';

import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import AgCellRazonSocial from '@/components/ProveedoresTable/AgCellRazonSocial.vue';
import AgCellEstatusBadge from '@/components/ProveedoresTable/AgCellEstatusBadge.vue';
import AgCellPerfilBadge from '@/components/ProveedoresTable/AgCellPerfilBadge.vue';
import AgCellRepsPendBadge from '@/components/ProveedoresTable/AgCellRepsPendBadge.vue';
import AgCellActions from '@/components/ProveedoresTable/AgCellActions.vue';
import AgFilter from '@/components/ProveedoresTable/AgFilter.vue';
import AgStatusBar from '@/components/AgStatusBar.vue';
import { mapState } from 'vuex';

export default {
  components: {
    AgGridVue,
    DiasCarga,
  },
  props: [
    'es_proveedor',
  ],
  data() {
    return {
      loading: false,
      exportLoading: false,
      generales: {},
      /** Configuracion de tabla ag-grid */
      // eslint-disable-next-line no-undef
      locale: AG_GRID_LOCALE_ES,
      context: null,
      gridOptions: {
        nameTable: 'agDashboard',
        suppressMenuHide: true,
      },
      defaultColDef: {
        floatingFilter: true,
        resizable: true,
        sortable: true,
        filter: true,
        unSortIcon: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'statusBarComponent', align: 'left' },
          { statusPanel: 'agSelectedRowCountComponent' },
        ],
      },
      frameworkComponents: {
        razonSocialTemplate: AgCellRazonSocial,
        badgeEstatusTemplate: AgCellEstatusBadge,
        badgePerfilTemplate: AgCellPerfilBadge,
        repsPendTemplate: AgCellRepsPendBadge,
        actionsTemplate: AgCellActions,
        checkFilterTemplate: AgFilter,
        statusBarComponent: AgStatusBar,
      },
      selectedRows: [],
      selectedRowKeys: [],
      headers: [
        {
          headerName: '#',
          field: 'row_num',
        },
        {
          headerName: 'Razon Social',
          field: 'RazonSocial',
        },
        {
          headerName: 'Rfc',
          field: 'Rfc',
        },
        {
          headerName: 'Email',
          field: 'Email',
          hide: true,
        },
        {
          headerName: 'Reg Patronal',
          field: 'RegPatronal',
        },
        {
          headerName: 'Rep Legal',
          field: 'RepLegal',
        },
        {
          headerName: 'Domicilio Fiscal',
          field: 'DomicilioFiscal',
        },
        {
          headerName: 'Nombre Contacto',
          field: 'NombreContacto',
        },
        {
          headerName: 'Telefono',
          field: 'Telefono',
        },
        {
          headerName: '¿Es Repse?',
          field: 'EsRepse',
        },
      ],
      columnDefs: [
        {
          headerName: '#',
          field: 'row_num',
          filter: false,
          pinned: 'left',
          width: '50px',
          resizable: false,
          cellClass: 'text-center line-numbers',
          cellRenderer: 'loadingRenderer',
        },
        {
          headerName: '#',
          field: 'ProveedorId',
          filter: false,
          hide: true,
          pinned: 'left',
          width: '50px',
          resizable: false,
          cellClass: 'text-center line-numbers',
          cellRenderer: 'loadingRenderer',
        },
        {
          headerName: 'Fecha Alta',
          field: 'FechaAlta',
          width: '140px',
          cellClass: 'text-center',
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'Razon Social',
          field: 'RazonSocial',
          filter: 'agTextColumnFilter',
          cellRendererSelector: () => ({
            component: 'razonSocialTemplate',
          }),
        },
        {
          headerName: 'RFC',
          field: 'Rfc',
          width: '120px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Estatus',
          field: 'EsActivo',
          cellRendererSelector: () => ({
            component: 'badgeEstatusTemplate',
          }),
          width: '110px',
          filter: 'checkFilterTemplate',
          sortable: false,
          suppressMenu: true,
          cellClass: 'text-center',
        },
        {
          headerName: 'Repse',
          field: 'EsRepse',
          cellRenderer: (params) => (params.value === 1 ? 'SI' : 'NO'),
          filter: 'checkFilterTemplate',
          sortable: false,
          suppressMenu: true,
          width: '55px',
          cellClass: 'text-center',
        },
        {
          headerName: 'Perfil',
          field: 'EstatusPerfil',
          width: '70px',
          cellRendererSelector: () => ({
            component: 'badgePerfilTemplate',
          }),
          filter: 'checkFilterTemplate',
          sortable: false,
          suppressMenu: true,
          cellClass: 'text-center',
        },
        {
          headerName: '¿Cfdis?',
          field: 'CfdisCargados',
          width: '65px',
          filter: 'checkFilterTemplate',
          sortable: false,
          suppressMenu: true,
          cellClass: 'text-center',
        },
        {
          headerName: 'Ordenes',
          field: 'OrdenesCargadas',
          width: '75px',
          filter: 'checkFilterTemplate',
          sortable: false,
          suppressMenu: true,
          cellClass: 'text-center',
        },
        {
          headerName: '¿Reps Pend.?',
          field: 'TotalSinRep',
          cellRendererSelector: () => ({
            component: 'repsPendTemplate',
          }),
          width: '120px',
          filter: 'checkFilterTemplate',
          suppressMenu: true,
          cellClass: 'text-center',
        },
        // {
        //   headerName: 'Lim. Carga',
        //   field: 'DiaLimCarga',
        //   cellClass: 'text-center',
        //   width: '85px',
        //   filter: false,
        //   sortable: false,
        //   suppressMenu: true,
        // },
        // {
        //   headerName: 'Lib. Carga',
        //   field: 'DiaLibCarga',
        //   cellClass: 'text-center',
        //   width: '85px',
        //   filter: false,
        //   sortable: false,
        //   suppressMenu: true,
        // },
        {
          headerName: 'Acciones',
          filter: false,
          floatingFilter: false,
          suppressMenu: true,
          sortable: false,
          // lockPosition: 'right',
          suppressMovable: true,
          pinned: window.innerWidth < 1650 ? 'right' : false,
          width: '250px',
          cellClass: 'text-center',
          cellRendererSelector: () => ({
            component: 'actionsTemplate',
            // params: {
            //   listaNegra: 'ListaNegra',
            //   esActivo: 'EsActivo',
            // },
          }),
        },
      ],
      rowModelType: 'serverSide',
      paginationPageSize: 20,
      filters: '',
    };
  },
  computed: {
    ...mapState(['aggrid']),
  },
  mounted() {
    this.context = { componentParent: this };
    this.getDashboard();
  },
  methods: {
    // Evento cuando la tabla se inicializa
    onGridReady(params) {
      // Creamos variable global de params (propiedates y funciones de agGrid)
      this.agGrid = params;

      // Llamada a la funcion que actualiza la tabla
      this.getData();
      this.gridSizeChanged(this.agGrid);
    },
    // Metodo para ajustar tamaño de tabla cuando las columnas no cubren por completo el grid
    gridSizeChanged(params) {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        if (!column.colDef.autoSize) { allColumnIds.push(column.colId); }
      });
      // params.columnApi.autoSizeColumns(allColumnIds);
    },
    saveColumnState() {
      const columnState = this.agGrid.columnApi?.getColumnState();
      this.$store.commit('SAVE_AGGRIDSTATE', { tblname: `${this.gridOptions?.nameTable}State`, columnstate: columnState });
    },
    gridFirstDataRendered() {
      const columnState = this.aggrid[`${this.gridOptions?.nameTable}State`];
      if (columnState) {
        this.agGrid.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
      }
    },
    // Metodo para actualizar filas seleccionadas
    rowSelected(params) {
      this.selectedRows = params.api.getSelectedNodes();
      this.selectedRowKeys = this.selectedRows.map((node) => node.data.UbicacionId);
    },
    // Metodo para abrir modal al dar doble click en una fila
    rowDblClick(/* params */) {
      if (this.$permisos_or([['Dashboard', 'Ver'], ['Dashboard', 'Editar']])) {
        // this.showModalEdit(params.data);
      }
    },
    // Metodo para limpiar todos los filtros
    clearFilters() {
      if (Object.entries(this.filters.filterModel).length) this.loading = true;
      this.agGrid.api.setFilterModel(null);
    },
    // Metodo para actualiza datos de la tabla incluyendo filtros
    updateTable() {
      this.getData();
    },
    // Metodo para actualizar datos de la tabla (server - side)
    getData() {
      this.agGrid.api.setServerSideDatasource(
        this.$createDataSource(
          this,
          apiClient,
          'proveedores', // ruta
        ),
      );
    },
    getDashboard() {
      // this.getProveedores();
      this.getGenerales();
    },
    // getProveedores() {
    //   apiClient.get('/proveedores')
    //     .then((res) => {
    //       this.rowsProvs = res.data;
    //     })
    //     .catch(() => {});
    // },
    getGenerales() {
      apiClient.get('/proveedores/generales')
        .then((res) => {
          this.generales = res.data;
        })
        .catch(() => {});
    },
    // Metodo para exporta toda la informacion a excel con los filtros elegidos, por lo que se hace una llamada al backend.
    exportExcel() {
      // Encabezados para el excel
      // const headers = this.columnDefs.map((v) => ({ [v.field]: v.headerName }));
      const headers = this.headers.map((v) => ({ [v.field]: v.headerName }));

      const params = {
        this: this,
        apiClient,
        XLSXSTYLE,
        url: 'proveedores/exportar',
        title: 'Listado de Proveedores',
        headers,
      };
      this.$exportExcel(params);
    },
    verPerfil(provId) {
      this.$router.push({ name: '/perfil', params: { ProvId: `${provId}` } }).catch(() => {});
    },
    verOrdenes(provId, nomProv) {
      this.$router.push({ name: '/ordenes', params: { ProvId: `${provId}`, PrevRoute: '/dashboard', NomProv: `${nomProv}` } }).catch(() => {});
    },
    verFacturas(provId, nomProv) {
      this.$router.push({ name: '/facturas', params: { ProvId: `${provId}`, PrevRoute: '/dashboard', NomProv: `${nomProv}` } }).catch(() => {});
    },
    verFacturasTodas() {
      this.$router.push({ name: '/facturas-todas', params: { PrevRoute: '/dashboard' } }).catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>

</style>

<template>
    <div class="btn-action-md">
      <b-button class="btn-detail mr-1"
        @click="verPerfil()"
        v-if="$ver(['Perfil'])"
        title="Ver datos del Proveedor">
        <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'eye', }" class="icon btn-icon"/> Datos</b-button>
      <b-button class="btn-detail mr-1"
        @click="verOrdenes()"
        v-if="$ver(['Ordenes'])"
        title="Ver ordenes del Proveedor"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'list-alt', }" class="icon btn-icon"/> Ordenes</b-button>
      <b-button class="btn-detail"
        @click="verFacturas()"
        v-if="$ver(['Facturas'])"
        title="Ver Cfdis del Proveedor">
        <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'file-invoice', }" class="icon btn-icon"/><span class="f-btn"> CFDIs</span></b-button>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
  ],
  data() {
    return {
    };
  },
  methods: {
    verPerfil() {
      this.params.context.componentParent.verPerfil(this.params.data.ProveedorId);
    },
    verOrdenes() {
      this.params.context.componentParent.verOrdenes(this.params.data.ProveedorId, this.params.data.RazonSocial);
    },
    verFacturas() {
      this.params.context.componentParent.verFacturas(this.params.data.ProveedorId, this.params.data.RazonSocial);
    },
  },
});
</script>

<style lang="scss" scoped>
.btn-icon {
  padding: 0 .30rem;
  width: 1.4rem !important;
  height: auto;
}
.f-btn {
  display: inline-flex;
  vertical-align: text-bottom;
}
</style>
